import { Steps, Card, Row, Col, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons/lib/icons";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_API_KEY, MIXPANEL_DEBUG } from "../constants";
mixpanel.init(MIXPANEL_API_KEY, { debug: MIXPANEL_DEBUG, ignore_dnt: true });

const { Meta } = Card;
const Step = Steps.Step;

const circleImage1 = "./../assets/1.png";
const circleImage2 = "./../assets/2.png";
const circleImage3 = "./../assets/3.png";

export default function AppSteps({ web3Modal, setRoute, loadWeb3Modal }) {
  return (
    <div className={web3Modal.cachedProvider ? "app-steps-logged-in" : "app-steps-not-logged-in"}>
      <div style={{ width: "90%", margin: "auto" }}>
        <h2 style={{ marginBottom: 0, fontWeight: "bold", fontSize: 40 }}>3 Easy Steps</h2>
        <div style={{ margin: "0px 0px 12px 0px" }}></div>
        <h5 className="app-step-subtitle">Create Your Will3 in 5 Minutes</h5>
        <div style={{ margin: "0px 0px 24px 0px" }}></div>
        <Row style={{ margin: "auto" }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <Card className="step-card" cover={<img alt="example" className="step-card-image" src={circleImage1} />}>
              <h4 style={{ fontWeight: "bold" }}>1) Approve</h4>
              <p className="step-description">
                Upon death, allow Will3 to disburse assets from your wallet{" "}
                <Tooltip placement="top" title="Assets can disubrsed ONLY after a future date that you define">
                  <InfoCircleOutlined style={{ fontSize: "12px", marginTop: 7, verticalAlign: "top" }} />
                </Tooltip>
              </p>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="step-card" cover={<img alt="example" className="step-card-image" src={circleImage2} />}>
              <h4 style={{ fontWeight: "bold" }}>2) Add Instructions</h4>
              <p className="step-description">Allot portions of your assets for each beneficiary</p>
            </Card>
          </Col>
          <Col span={8}>
            <Card className="step-card" cover={<img alt="example" className="step-card-image" src={circleImage3} />}>
              <h4 style={{ fontWeight: "bold" }}>3) Set Trigger</h4>
              <p className="step-description">Specify when assets should be distributed</p>
            </Card>
          </Col>
        </Row>
        <div style={{ marginTop: 48 }}>
          {!web3Modal.cachedProvider ? (
            <Button
              style={{
                height: "auto",
                padding: 18,
                minWidth: "800px !important",
                maxWidth: "900px !important",
              }}
              shape="round"
              size="large"
              className="connect-wallet-button-landing"
              onClick={() => loadWeb3Modal}
            >
              <h6 style={{ color: "white", margin: "auto", fontSize: 24 }}>Connect Wallet</h6>
            </Button>
          ) : (
            <Button
              style={{ height: "auto", padding: 18, width: "250px" }}
              shape="round"
              size="large"
              className="connect-wallet-button-landing"
            >
              <Link
                onClick={() => {
                  setRoute("/create");
                  window.scrollTo(0, 0);
                  mixpanel.track("Clicked App Steps CTA Button", {
                    page: "home",
                  });
                }}
                to="/create"
              >
                <h6 style={{ color: "white", margin: "auto", fontSize: 24 }}>Create Will3 Now</h6>
              </Link>
            </Button>
          )}
        </div>
        {/*
        <Card className="step-card" cover={<img alt="example" src={circleImage3} />}>
          <h5>Set Notifications</h5>
          <p>Will3 supports private notifications via email and SMS</p>
        </Card>
        */}
      </div>
    </div>
  );
}
